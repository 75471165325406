exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-casos-tsx": () => import("./../../../src/pages/casos.tsx" /* webpackChunkName: "component---src-pages-casos-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mayoristas-tsx": () => import("./../../../src/pages/mayoristas.tsx" /* webpackChunkName: "component---src-pages-mayoristas-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-servicio-administrados-impresion-tsx": () => import("./../../../src/pages/servicio-administrados-impresion.tsx" /* webpackChunkName: "component---src-pages-servicio-administrados-impresion-tsx" */),
  "component---src-pages-servicio-digitalizacion-procesos-tsx": () => import("./../../../src/pages/servicio-digitalizacion-procesos.tsx" /* webpackChunkName: "component---src-pages-servicio-digitalizacion-procesos-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */)
}

